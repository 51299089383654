import React from "react";
// Customizable Area Start
import { Row, Col, Image, Form, Input, Typography, Button, Spin } from "antd";
import "antd/dist/antd.css";
import { iconImage, style, AppImage, AndroidImage, iosImage } from "./assets";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import ResetPasswordController from "./resetPasswordController.web";
import { AlertMessage } from "../../alert/src/AlertMessage.web";
const { Title, Text, Link } = Typography;
const passwrdRegex = /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;
// Customizable Area End

export default class ForgoResetPassword extends ResetPasswordController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <>
        <Row>
          <Col span={14} xs={0} sm={12} md={12} lg={14} xl={14} xxl={14}>
            {!this.state.resetPasswordBannerImage ? (
              <Spin />
            ) : (
              <Image
                preview={false}
                src={this.state.resetPasswordBannerImage}
                wrapperStyle={{ height: "100%", width: "100%" }}
                style={{ height: "100%", width: "100%" }}
              />
            )}
          </Col>
          <Col
            span={10}
            xs={24}
            sm={12}
            md={12}
            lg={10}
            xl={10}
            xxl={10}
            className={style.top_padding}
          >
            <Row>
              <Col span={4} />
              <Col span={16}>
                <Row>
                  <Col span={6} />
                  <Col span={12}>
                    <Image
                      preview={false}
                      className={style.logopadding}
                      src={iconImage}
                    />
                  </Col>
                  <Col span={6} />
                </Row>

                <Row className={style.loginText}>
                  <Title
                    className={style.w_100_center}
                    level={3}
                    style={{ fontWeight: 500 }}
                  >
                    Reset Password
                  </Title>
                </Row>
                <br />
                <Form
                  initialValues={{
                    new_password: "",
                    confirm_password: ""
                  }}
                  onFinish={this.handlePassword}
                >
                  <Form.Item
                    rules={[
                      { required: true, message: "Required field" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("new_password").match(passwrdRegex)
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
                            )
                          );
                        }
                      })
                    ]}
                    name="new_password"
                  >
                    <Input.Password
                      className={style.input_fields}
                      name="new_password"
                      data-testid="newPassword"
                      placeholder="New Password"
                      size="large"
                      iconRender={visible =>
                        visible ? (
                          <EyeFilled style={{ color: "#CFD9E2" }} />
                        ) : (
                          <EyeInvisibleFilled style={{ color: "#CFD9E2" }} />
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      { required: true, message: "Required field" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("new_password") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Both Passwords Should  Match")
                          );
                        }
                      })
                    ]}
                    name="confirm_password"
                  >
                    <Input.Password
                      className={style.input_fields}
                      name="confirm_password"
                      placeholder="Confirm Password"
                      size="large"
                      data-testid="confirmPassword"
                      iconRender={visible =>
                        visible ? (
                          <EyeFilled style={{ color: "#CFD9E2" }} />
                        ) : (
                          <EyeInvisibleFilled style={{ color: "#CFD9E2" }} />
                        )
                      }
                    />
                  </Form.Item>
                  <Spin spinning={this.state.loading}>
                    <Button
                      className={style.btn_colors}
                      type="primary"
                      htmlType="submit"
                      shape="round"
                      size={"large"}
                      style={{ width: "100%" }}
                    >
                      Reset Password
                    </Button>
                  </Spin>
                </Form>
                <br />
                <Row className={style.loginText}>
                  <Link
                    className={style.w_100_center}
                    style={{
                      fontSize: "16px",
                      color: "#005574",
                      fontWeight: 500
                    }}
                    href="/"
                  >
                    Back to Log In
                  </Link>
                </Row>
              </Col>
              <Col span={4} />
            </Row>
          </Col>
        </Row>
        <Row style={{ backgroundColor: "#f5f5f5" }}>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={14} xxl={14}>
            <Row
              className={style.app_alignCenter}
              style={{ justifyContent: "flex-end", width: "100%" }}
            >
              <Image
                preview={false}
                src={AppImage}
                style={{ objectFit: "fill" }}
                // wrapperStyle={{ width: "100%" }}
              />
            </Row>
          </Col>
          <Col
            span={12}
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={10}
            xxl={10}
            // className={style.app_store}
          >
            <Row
              // className={style.app_alignCenter}
              style={{
                height: "100%",
                paddingRight: "40px",
                alignItems: "center",
                paddingLeft: "40px"
              }}
            >
              <Col span={6} xs={0} sm={6} md={3} lg={3} xl={3} xxl={6} />
              <Col span={10} xs={24} sm={24} md={18} lg={18} xl={18} xxl={10}>
                <Row>
                  <Title level={3}>Available for Android and IOS</Title>
                  <Text
                    type="secondary"
                    className={style.w_100}
                    style={{
                      lineHeight: "1.3rem",
                      paddingBottom: "20px",
                      fontSize: "1.1rem"
                    }}
                  >
                    Download applied app from your Play Store or App Store
                  </Text>
                  <Col span={11}>
                    <Image preview={false} src={AndroidImage} />
                  </Col>
                  <Col span={2} />
                  <Col span={11}>
                    <Image preview={false} src={iosImage} />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={8} sm={8} md={3} lg={3} xl={3} xxl={8} />
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            paddingTop: "10px",
            paddingRight: "40px",
            justifyContent: "center",
            paddingLeft: "40px"
          }}
        >
          <p>©2021 applied. All rights reserved.</p>
          <a
            href="/privacy-policy"
            style={{
              color: "#798da0",
              fontWeight: 600,
              opacity: 0.8
            }}
            target="_blank"
          >
            &nbsp;&nbsp;Privacy Policy&nbsp;&nbsp;
          </a>
          <div
            style={{
              opacity: "20%",
              borderLeft: "2px solid black",
              height: "20px"
            }}
          />
          <a
            href="/terms-and-conditions"
            target="_blank"
            style={{
              opacity: 0.8,
              fontWeight: 600,
              color: "#798da0"
            }}
          >
            &nbsp;&nbsp;Terms of use
          </a>
        </Row>
        <AlertMessage
          title={this.state.errorContent.title}
          isModalVisible={this.state.isErrorModalShow}
          body={this.state.errorContent.body}
          isError={this.state.errorContent.isError}
          onOk={() => {
            this.setState({ isErrorModalShow: false, errorContent: {} });
          }}
        />
      </>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
